import { Fragment, useState } from "react";
import {
  NoRecordFoundTable,
  TransactionStatus,
  UserInfoInTable,
  YesNoStatus,
} from "_components";
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import { userStatus } from "_constants";
import { useLogin } from "_hooks/Shared";
import { useMutation } from "@apollo/client";
import {
  SEND_EMAIL_CONFIRMATION_BY_ID_MUTATION,
  UPDATE_USER_STATUS_BY_ID_MUTATION,
} from "_queries/Shared";
import { toast } from "react-toastify";
import { timestampToDate } from "_utils";

const columns = [
  { label: "Title", class: "min-w-250px" },
  { label: "Email", class: "min-w-100px" },
  { label: "Phone", class: "min-w-200px" },
  { label: "Email Confirmed", class: "min-w-100px" },
  { label: "Joined Date", class: "min-w-100px" },
  { label: "Status", class: "min-w-100px" },
  { label: "Action", class: "text-center min-w-200px" },
];

export const ListingTable = ({ data, refetch }) => {
  const [collapsedRows, setCollapsedRows] = useState({});

  const [sendConfirmationEmailToUser] = useMutation(
    SEND_EMAIL_CONFIRMATION_BY_ID_MUTATION,
    {
      errorPolicy: "all",
    }
  );
  const [updateUserStatus] = useMutation(UPDATE_USER_STATUS_BY_ID_MUTATION, {
    errorPolicy: "all",
  });
  const loginAndNavigate = useLogin();

  const handleLoginButton = (id) => {
    loginAndNavigate(id);
  };

  const toggleRow = (id) => {
    setCollapsedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleSendConfirmationEmail = async (id) => {
    const response = await sendConfirmationEmailToUser({
      variables: { id },
    });

    if (response) {
      const data =
        response && response.data && response.data.sendConfirmationEmail;

      if (data) {
        toast.success(
          "Email confirmation has been sent to user email address."
        );
      }
    }
  };

  const handleUpdateStatus = async (id) => {
    const response = await updateUserStatus({
      variables: { id, status: "active" },
    });

    if (response) {
      const data =
        response &&
        response.data &&
        response.data.updateStatus &&
        response.data.updateStatus._id;

      if (data) {
        refetch();
        toast.success("User status has been changed successfully.");
      }
    }
  };

  return (
    <Fragment>
      <Table className="table align-middle table-nowrap mb-0">
        <thead className="table-light">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className="list form-check-all">
          {data &&
            data.map((user, index) => (
              <Fragment key={index}>
                <tr key={index} onClick={() => toggleRow(index)}>
                  <td>
                    <UserInfoInTable user={user} />
                  </td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>
                    <YesNoStatus
                      status={user.isEmailConfirmed === "true" ? "YES" : "NO"}
                    />
                  </td>
                  <td>{timestampToDate(user.createdAt)}</td>
                  <td>
                    <TransactionStatus status={user.status} />
                  </td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="button"
                        className="btn-soft-secondary btn-sm"
                      >
                        <i className="ri-more-fill"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => handleLoginButton(user._id)}
                        >
                          <i className="ri-shield-user-line align-bottom me-2 text-muted"></i>
                          Login
                        </DropdownItem>
                        {[userStatus.PENDING, userStatus.BLOCKED].includes(
                          user.status
                        ) && (
                          <DropdownItem
                            onClick={() => handleUpdateStatus(user._id)}
                          >
                            <i className="ri-user-unfollow-line align-bottom me-2 text-muted"></i>
                            Active User
                          </DropdownItem>
                        )}
                        {user.isEmailConfirmed === "false" && (
                          <DropdownItem
                            onClick={() =>
                              handleSendConfirmationEmail(user._id)
                            }
                          >
                            <i className="ri-user-unfollow-line align-bottom me-2 text-muted"></i>
                            Send Email Confirmation
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
                <tr>
                  <td colSpan="7">
                    <Collapse isOpen={collapsedRows[index]}>
                      <Table className="table table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th>Payment Method</th>
                            <th>Email</th>
                            <th>Bank Name</th>
                            <th>Bank Address</th>
                            <th>Account Type</th>
                            <th>Account Name</th>
                            <th>Account No</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user.bankAccountDetails.length > 0 ? (
                            user.bankAccountDetails.map(
                              (accountDetail, index) => (
                                <tr key={index}>
                                  <td className="text-capitalize">
                                    {accountDetail.paymentMethod}
                                  </td>
                                  <td>{accountDetail.email}</td>
                                  <td>{accountDetail.bankName}</td>
                                  <td>{accountDetail.bankAddress}</td>
                                  <td>{accountDetail.accountType}</td>
                                  <td>{accountDetail.accountName}</td>
                                  <td>{accountDetail.accountNumber}</td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td
                                colSpan="7"
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                No Account Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Collapse>
                  </td>
                </tr>
              </Fragment>
            ))}
        </tbody>
      </Table>
      {data && data.length === 0 && <NoRecordFoundTable />}
    </Fragment>
  );
};
