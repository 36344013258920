import { Fragment } from "react";
import { firstLetterCapital } from "_utils";
import {
  NoRecordFoundTable,
  TransactionStatus,
  OfferInfoInTable,
  ApplyOfferButton,
  GetTrackingLinkButton,
} from "_components";
import { UserAppliedOfferStatus } from "_constants";

const columns = [
  { label: "Advertiser", class: "min-w-250px" },
  { label: "Category", class: "min-w-250px" },
  { label: "Commission", class: "min-w-100px" },
  { label: "Country", class: "min-w-200px" },
  { label: "Status", class: "min-w-100px" },
  { label: "Action", class: "text-center min-w-200px" },
];

export const TopOffers = ({ data, refetch, loadingOffers }) => {
  return (
    <Fragment>
      <table className="table align-middle table-nowrap mb-0">
        <thead className="table-light">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className="list form-check-all">
          {data &&
            data.map((offer) => (
              <tr key={offer._id}>
                <td>
                  <OfferInfoInTable offer={offer} />
                </td>
                <td>{offer.category?.categoryName}</td>
                <td>
                  <span>
                    <span className="badge bg-light text-body fs-12 fw-medium">
                      <i className="mdi mdi-star text-warning me-1"></i>
                      {offer.commission}%
                    </span>
                  </span>
                </td>
                <td>{firstLetterCapital(offer.country)}</td>
                <td>
                  {offer.userAppliedStatus === null ? (
                    <TransactionStatus status={`unapplied`} />
                  ) : (
                    <TransactionStatus status={offer.userAppliedStatus} />
                  )}
                </td>
                {offer.userAppliedStatus ===
                  UserAppliedOfferStatus.APPROVED && (
                  <td className="text-center">
                    <GetTrackingLinkButton offer={offer} />
                  </td>
                )}
                {[
                  UserAppliedOfferStatus.PENDING,
                  UserAppliedOfferStatus.DECLINED,
                ].includes(offer.userAppliedStatus) && (
                  <td className="text-center"></td>
                )}
                {offer.userAppliedStatus === null && (
                  <td className="text-center">
                    <ApplyOfferButton
                      offer={offer}
                      refetch={refetch}
                      loadingOffers={loadingOffers}
                    />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {data && data.length === 0 && <NoRecordFoundTable />}
    </Fragment>
  );
};
