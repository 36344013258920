import { Fragment } from "react";
import { timestampToDate } from "_utils";
import {
  NoRecordFoundTable,
  TransactionStatus,
  OfferInfoInTable,
} from "_components";
import { invoiceNumber } from "_utils/invoice-number";

const columns = [
  { label: "Date", class: "min-w-100px" },
  { label: "Transaction ID", class: "min-w-200px" },
  { label: "ref1", class: "min-w-150px" },
  { label: "ref2", class: "min-w-150px" },
  { label: "Advertiser", class: "min-w-150px" },
  { label: "Currency", class: "min-w-50px" },
  { label: "Sale Amount", class: "min-w-50px" },
  { label: "Commission", class: "min-w-50px" },
  { label: "Invoice No", class: "min-w-50px" },
  { label: "Status", class: "min-w-50px" },
];

export const ListingTable = ({ data }) => {
  return (
    <Fragment>
      <table className="table align-middle table-nowrap mb-0">
        <thead className="table-light">
          <tr>
            {columns.map((column, index) => (
              <th className={column.class} key={index}>
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="list form-check-all">
          {data &&
            data.map((transaction) => (
              <tr key={transaction._id}>
                <td>{timestampToDate(transaction.transactionDate)}</td>
                <td>{transaction.referenceId}</td>
                <td>{transaction.reference.ref1}</td>
                <td>{transaction.reference.ref2}</td>
                <td>
                  <OfferInfoInTable offer={transaction.offer} />
                </td>
                <td>{transaction.currency?.toUpperCase()}</td>
                <td>{transaction.saleAmount.toFixed(2)}</td>
                <td>{transaction.commission.toFixed(2)}</td>
                <td>
                  {transaction.invoice &&
                    invoiceNumber(transaction.invoice?.invoiceNo)}
                </td>
                <td>
                  <TransactionStatus status={transaction.status} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {data && data.length === 0 && <NoRecordFoundTable />}
    </Fragment>
  );
};
