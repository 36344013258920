import { Fragment } from "react";
import { firstLetterCapital } from "_utils";
import {
  NoRecordFoundTable,
  TransactionStatus,
  OfferInfoInTable,
  DeleteOfferButton,
  YesNoStatus,
  RestoreOfferButton,
} from "_components";
import { Link } from "react-router-dom";

const columns = [
  { label: "Advertiser", class: "min-w-250px" },
  { label: "Category", class: "min-w-250px" },
  { label: "Commission", class: "min-w-100px" },
  { label: "Country", class: "min-w-200px" },
  { label: "Platform", class: "min-w-200px" },
  { label: "Approval State", class: "min-w-100px" },
  { label: "Deleted", class: "min-w-100px" },
  { label: "Action", class: "text-center min-w-200px" },
];

export const ListingTable = ({ data, refetch }) => {
  return (
    <Fragment>
      <table className="table align-middle table-nowrap mb-0">
        <thead className="table-light">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody className="list form-check-all">
          {data &&
            data.map((offer) => (
              <tr key={offer._id}>
                <td>
                  <OfferInfoInTable offer={offer} />
                </td>
                <td>{offer.category?.categoryName}</td>
                <td>
                  <span>
                    <span className="badge bg-light text-body fs-12 fw-medium">
                      <i className="mdi mdi-star text-warning me-1"></i>
                      {offer.commission}%
                    </span>
                  </span>
                </td>
                <td>{firstLetterCapital(offer.country)}</td>
                <td>{firstLetterCapital(offer.platform)}</td>
                <td>
                  <TransactionStatus status={offer.approval} />
                </td>
                <td>
                  <YesNoStatus status={offer.deletedAt ? "YES" : "NO"} />
                </td>
                <td className="text-center">
                  <div className="flex-shrink-0 ms-4">
                    <ul className="list-inline hstack gap-2 mb-0">
                      <li className="list-inline-item edit">
                        <Link
                          to={`${offer._id}/edit`}
                          className="edit-item-btn"
                        >
                          <i className="ri-pencil-fill fs-16"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        {offer.deletedAt ? (
                          <RestoreOfferButton offer={offer} refetch={refetch} />
                        ) : (
                          <DeleteOfferButton offer={offer} refetch={refetch} />
                        )}
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {data && data.length === 0 && <NoRecordFoundTable />}
    </Fragment>
  );
};
